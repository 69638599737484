#cg__homepageSlider .uranus.tparrows{
	width: 50px;
	height: 50px;
	background: rgba(255,255,255,0);
}
#cg__homepageSlider .uranus.tparrows:before{
	width: 50px;
	height: 50px;
	line-height: 50px;
	font-size: 40px;
	transition: all 0.3s;
}
#cg__homepageSlider .uranus.tparrows:hover:before{ opacity:0.75; }
.cg__homepageSlider-wrapper {
	margin: 0px auto;
	background: transparent;
	padding: 0px;
	margin-top: 0px;
	margin-bottom: 0px;
}
