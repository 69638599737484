.cg__accordion {
	border: 1px solid #E3E3E3;
	border-radius: 4px;
	border-bottom: 0px;
	.cg__accordion-group {
		margin-bottom: 0;
		border: 0;
		border-bottom: 1px solid #E3E3E3;
		background-color: transparent;
		padding: 0;
		box-shadow: none;
		margin: 0;
		border-radius: 3px;
		&.is-opened {
			.cg__accordion-title {
				background-color: #F8F8F8;
				color: #CD2122;
				border-bottom: 1px solid #EEEEEE;
				&:after {
				right: 25px;
				top: 50%;
				transform: rotate(45deg);
				}
				&:before {
					right: 31px;
					top: 50%;
					transform: rotate(-45deg);
				}
			}
		}
	}
}
.cg__accordion-title {
	cursor: pointer;
	border-radius: 3px 3px 0 0;
	color: #505050;
	background-color: #fff;
	display: block;
	width: 100%;
	text-align: left;
	padding: 17px 60px 15px 25px;
	font-size: 14px;
	font-weight: 600;
	position: relative;
	&:after, &:before {
		position: absolute;
		content: ' ';
		width: 10px;
		height: 2px;
		display: block;
		background: #ddd;
		transition: all .3s ease-in-out;
		right: 25px;
		top: 50%;
	}
	&:after {
		right: 25px;
		top: 50%;
		transform: rotate(-45deg);
	}
	&:before {
		right: 31px;
		top: 50%;
		transform: rotate(45deg);
	}
	&:hover {
		background: #F8F8F8;
		color: #111;
	}
}
.cg__accordion-content {
	display: none;
	padding: 0 15px;
	.row {
		padding: 20px 10px;
		background-color: #F8F8F8;
		h4 {
			font-size: 14px;
			line-height: 20px;
			font-weight: 700;
			font-style: normal;
			margin-bottom: 20px;
			color: #535353;
		}
		p {
			line-height: 1.7;
		}
	}
	ul {
		&.cg__list--style1 {
			li {
				&:before {
					content: "\e013";
					opacity: .6;
					margin-right: 10px;
					display: inline-block;
					font-family: Glyphicons Halflings;
					font-style: normal;
					font-weight: 400;
					line-height: 1;
				}
			}
		}
	}
	a {
		color: #000;
		&:hover {
			color: #{$templateColor;}
		}
	}
}
