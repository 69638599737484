/*** Totop button ***/
.totop {
	height: 9px;
	opacity: 0;
	position: fixed;
	right: -60px;
	width: 49px;
	z-index: 999;
	display: block;
	top: 85%;
	background-repeat: no-repeat;
	background-position: center 15px;
	background-color: #404040;
	font-size: 9px;
	font-weight: 900;
	color: #fff;
	text-align: center;
	line-height: 1;
	border-radius: 2px;
	padding: 28px 0 21px 0;
	transition: all 0.2s ease-out;
}
.totop-vissible {
	right: 10px;
	opacity: 0.7;
}
.totop:before {
	position: absolute;
	content: "\e080";
	top: 10px;
	left: 50%;
	margin-left: -6px;
	font-size: 11px;
	display: inline-block;
	font-family: "Glyphicons Halflings";
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	transform: rotate(-90deg);
}
.totop:hover {
	color: #fff;
}
.totop:focus {
	color: #fff;
}
