/* header logo */
.cg__logoContainer {
	float: left;
	img {
		width: 188px;
		height: 188px;
		@include max_tablet {
			margin: auto;
			display: block;
		}
	}
	@include max_tablet {
		width: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

/* Top menu block */
.cg__topMenu-wrapper {
	position: relative;
	@include max_tablet {
		margin-right: auto;
	}
	.cg__topMenu-trigger {
		@include max_tablet {padding-left: 20px;}
		display: none;
		@include max_ldesktop {
			display: block;
			width: 18px;
			height: 14px;
			position: relative;
			cursor: pointer;
			margin-left: 10px;
			margin-right: 10px;
		}
		@include max_tablet {margin: auto;}
		&:before {
			opacity: .7;
			content: "";
			display: block;
			border-radius: 2px;
			height: 3px;
			width: 3px;
			background-color: #fff;
			box-shadow: 0 6px 0 #fff, 0 12px 0 #fff;
		}
	}

	/* top menu */
	.cg__topMenu {
		li a {
			font-weight: 400;
			text-transform: capitalize;
			@include max_ldesktop {
				color: #6f6b6b;
				background: #fff;
				width: 100%;
				font-weight: 400;
				font-size: 11px;
				padding: 6px 10px;
				display: block;
				border-top: 1px solid #eee;
				&:hover {
					color: #444;
					background: #f5f5f5;
					opacity: .9;
				}
			}
		}
		li {
			display: inline;
			@include min_ldesktop { position: relative; }
			@include max_ldesktop {
				display: block;
				width: 100%;
				float: none;
				margin: 0;
				padding: 0;
			}
		}
		@include max_ldesktop {
			display: none;
			float: none;
			margin: 0;
			list-style: none;
			margin-top: 0;
			min-width: 240px;
			background-color: #fff;
			padding: 0;
			border-radius: 2px;
			position: absolute;
			top: 25px;
			z-index: 11;
			right: -10px;
		}
		@include max_tablet {left: 0px;}
	}
	&.is-opened .cg__topMenu {
		@include max_ldesktop {display: block;}
	}
}

/* Login panel */
.cg__loginPanel{
	li a {
		font-weight: 700;
		text-transform: uppercase;
	}
	li {margin: 0px;}
	.glyphicon {font-size: 14px;}
}

/* languages panel */
.cg__languages-link {
	&:hover {color: #fff;}
	span {font-weight: 700;}
	i {margin-right: 5px;}
	.glyphicon {font-size: 11px;}
}
.cg_language-panel {
	margin-right: -10px;
	position: absolute;
	left: auto;
	width: auto;
	min-width: 120px;
	height: 0;
	opacity: 0;
	visibility: hidden;
	margin-left: -11px;
	right: 0;
	z-index: 101;
	transition: all .2s ease-out;
	.cg_language-inner {
		padding-left: 0;
		padding-right: 0;
		margin: 4px;
		list-style: none;
		background: #f5f5f5;
		padding: 10px 0px;
		margin-top: 5px;
		position: relative;
		box-shadow: 0 0 5px rgba(0,0,0,.4);
		border-radius: 2px;
		li a {
			font-size: 10px;
			color: #444;
			padding: 0 5px 0 10px;
			display: block;
			text-transform: uppercase;
			margin-top: 1px;
			padding-right: 10px;
		}
		li:hover a {
			color: #000;
			background: rgba(0,0,0,.1);
		}
		li a img {
			width: auto;
			margin-right: 5px;
		}
	}
}
.cg__languages li:hover .cg_language-panel{
	opacity: 1;
	height: auto;
	visibility: visible;
}

/* Cta button */
.cg__ctaButton {
	@include max_tablet {width: 60%;}
	display: table-cell;
	vertical-align: middle;
	.cg__btn {
		margin-left: 20px;
		@include max_tablet {float: right;}
		@include mobile {
			margin-left: 0px;
			padding: 16px 10px;
		}
	}
}

/* Main menu block */
.cg__menuWrapper {
	display: table-cell;
	vertical-align: middle;
	@include max_tablet {width: 40%;}
	.cg__mainMenu-trigger {
		@include max_tablet {padding-left: 20px;}
		.cg__menuBurger {
			width: 20px;
			vertical-align: middle;
			position: relative;
			display: inline-block;
			height: 22px;
			margin-right: 10px;
			transform: rotate(0deg);
			transition: .2s ease-out;
			cursor: pointer;
			opacity: .8;
			span {
				height: 3px;
				background: hsla(0,0%,100%,.85);
				display: block;
				position: absolute;
				width: 100%;
				border-radius: 2px;
				opacity: 1;
				left: 0;
				transform: rotate(0deg);
				transition: .25s ease-in-out;
				&:first-child {top: 0;}
				&:nth-child(2) {top: 6px;}
				&:nth-child(3) {top: 12px;}
			}
		}
		@include min_desktop {display: none;}
	}
	.cg__mainMenu {
		margin-left: 20px;
			margin-right: 20px;
		margin-bottom: 0px;
		text-transform: uppercase;
		> li {
			margin-left: 5px;
			float: left;
			position: relative;
			a {
				color: #ffffff;
				z-index: 1;
				position: relative;
				padding: 6px 12px;
				text-decoration: none;
				font-size: 13px;
				line-height: 30px;
				font-weight: 700;
				font-style: normal;
				transition: padding .2s ease-in-out,transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
				font-family: #{$titleFont};
			}
			a.active span{
				border-bottom: 2px solid currentColor;
				padding-bottom: 3px;
			}
		}
		.has-submenu {
			 &:hover .cg__subMenu{
				left: 0;
				opacity: 1;
				top: 100%;
				visibility: visible;
				z-index: 100;
			}
			.cg__subMenu {
				top: 120%;
				transform: translateY(10px);
				opacity: 0;
				visibility: hidden;
				transition: opacity .4s ease,top .45s ease;
				position: absolute;
				border-radius: 2px;
				border: 1px solid hsla(0,0%,100%,.8);
				box-shadow: 0 0 4px rgba(0,0,0,.4);
				padding: 6px 0;
				background: #f5f5f5;
				li {
					position: relative;
					a {
						display: block;
						font-size: 11px;
						line-height: 22px;
						font-weight: 700;
						color: #686868;
						font-style: normal;
						position: relative;
						min-width: 230px;
						border-bottom-width: 1px;
						border-bottom-style: solid;
						border-bottom-color: rgba(0,0,0,.03);
						padding: 10px 20px;
						transition: padding-left .35s ease,border-right .35s ease;
						font-family: #{$titleFont};
						&:hover {
							color: #323232;
							background: rgba(0,0,0,.04);
						}
					}
				}
				&:before {
					content: "";
					display: block;
					position: absolute;
					top: -12px;
					left: 0;
					width: 100%;
					height: 12px;
				}
				&:after {
					content: "";
					display: block;
					position: absolute;
					top: -11px;
					left: 14px;
					border: 6px solid transparent;
					border-bottom-width: 5px;
					border-bottom-color: #f5f5f5;
				}
			}
			&:after {
				content: "";
				opacity: 1;
				position: absolute;
				height: 1px;
				width: 7px;
				bottom: calc(50% - 12px);
				left: 17px;
				background-color: hsla(0,0%,100%,.4);
				transition: opacity .2s ease-in-out;
				bottom: calc(52% - 14px);
			}
		}
		@include max_desktop {display:none;}
	}
	@include min_tablet {
		margin-left: 20px;
		margin-right: 20px;
	}
}

/* search button */
.cg__search {
	.cg__searchBtn {
		margin-left: 10px;
		display: none;
		@include max_desktop {
			display: inline-block;
			background: rgba(0,0,0,.5);
			position: relative;
			z-index: 1;
			height: 30px;
			width: 30px;
			text-align: center;
			line-height: 30px;
			font-size: 12px;
			margin: 0;
			border-radius: 2px;
		}
	}
	.cg__search-container {
		width: 220px;
		margin-left: 10px;
		position: relative;
		@include max_desktop {
			display: none;
			position: absolute;
			right: 0;
			padding: 30px;
			background: #f4f4f4;
			width: 100%;
			border-bottom: 1px solid #fff;
			z-index: 11;
			box-shadow: 0 3px 7px rgba(0,0,0,.2);
		}
		&.panel-opened {
			@include max_desktop {
				display: block;
			}
		}
		input {
			color: #fff;
			background-color: hsla(0,0%,100%,.1);
			position: relative;
			border: none;
			font-size: 12px;
			font-style: italic;
			margin: 0;
			display: inline-block;
			box-shadow: none;
			outline: none;
			width: 100%;
			padding: 2px 30px 2px 15px;
			border-radius: 3px;
			height: 34px;
			transition: all .15s ease-out;
			&:hover { background-color: hsla(0,0%,100%,.15);}
			&:focus {
				color: #e3e3e3;
				font-style: normal;
				background-color: hsla(0,0%,100%,.2);
				box-shadow: 0 0 0 2px hsla(0,0%,100%,.2);
			}
			@include max_desktop {
				background: none;
				border: none;
				font-size: 12px;
				color: #444!important;
				padding: 5px 0;
				margin: 0;
				display: inline-block;
				box-shadow: none;
				outline: none;
				font-style: normal;
				width: calc(100% - 30px);
			}
		}
		button {
			color: hsla(0,0%,100%,.4);
			width: 20px;
			background-color: transparent;
			border: none;
			padding: 0;
			display: inline-block;
			height: 14px;
			line-height: 14px;
			vertical-align: text-top;
			position: absolute;
			right: 10px;
			top: 50%;
			margin-top: -8px;
			outline: none;
			z-index: 1;
		}
		form {
			@include max_desktop {
				border-bottom: 1px solid #ccc;
				margin: 0;
				position: relative;
			}
		}
	}
}

/* social panel */
.cg__socialIcons {
	li {
		display: inline-block;
		margin-left: 5px;
		margin-right: 5px;
		vertical-align: text-top;
		@include max_tablet {margin: 0px;}
	}
	li a {
		font-size: 22px;
		font-weight: 400;
		opacity: 1;
		position: relative;
		border-radius: 3px;
		text-align: center;
	}
}

/* header responsive menu */
.cg__resMenu {
	padding: 0;
	margin: 0;
	list-style: none;
	right: 0;
	top: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	background: #fff;
	overflow: hidden;
	backface-visibility: hidden;
	transition: transform .5s ease;
	z-index: 10000;
	transform: translateX(100%);
	transform-style: preserve-3d;
	@include min_tablet {
		max-width: 400px;
	}
	li {
		backface-visibility: hidden;
		border-color: rgba(0, 0, 0, 0.15);
		position: static;
		border-bottom: 1px solid rgba(0,0,0,.15);
		&.cg__resMenu-back {
			.cg__resMenu-backLink {
				font-size: 14px;
				padding: 12px 28px 12px;
				display: block;
				font-weight: 400;
				cursor: pointer;
				color: rgba(0,0,0,.45);
				text-align: center;
				text-transform: capitalize;
				&:hover {
					color: rgba(0, 0, 0, 0.85);
					text-decoration: none;
				}
			}
			.cg__resMenu-backIcon {
				left: 0;
				right: auto;
				border-left: none;
				border-right: 1px solid rgba(0, 0, 0, 0.15);
				position: absolute;
				width: 49px;
				height: 45px;
				line-height: 45px;
				vertical-align: middle;
				text-align: center;
				cursor: pointer;
				color: rgba(0, 0, 0, 0.45);
				top: 0;
			}
		}
		a {
			padding: 12px 28px 12px;
			display: block;
			font-weight: 400;
			color: rgba(0,0,0,.45);
			text-transform: uppercase;
			font-size: 13px;
			&.active {
				background-color: rgba(0,0,0,.05);
				color: rgba(0,0,0,.85);
			}
			&:hover {color: rgba(0,0,0,.85);}
		}
	}
	/* rez submenu */
	.cg__res-submenu {
		.cg__res-submenu-trigger {
			position: absolute;
			right: 0;
			width: 49px;
			height: 45px;
			border-left: 1px solid rgba(0,0,0,.15);
			line-height: 45px;
			vertical-align: middle;
			text-align: center;
			cursor: pointer;
			color: rgba(0,0,0,.45);
			top: auto;
		}
		> ul {
			padding: 0;
			margin: 0;
			list-style: none;
			right: 0;
			top: 0;
			position: absolute;
			width: 100%;
			height: 100%;
			background: #fff;
			overflow: hidden;
			backface-visibility: hidden;
			transition: -webkit-transform .5s ease;
			transition: transform .5s ease;
			z-index: 10000;
			transform: translateX(100%);
			transform-style: preserve-3d;
			&.cg__menu--visible {
				display: block;
				transform: translateZ(0);
			}
		}
	}
	&.cg__menu--visible {
		transform: translateZ(0);
		display: block;
	}
}

/* header navbar */
.cg__headerNavbar {
	height: 160px;
	position: absolute;
	width: 100%;
	z-index: 99;
	background: transparent;
	@include max_tablet {
		background: #181834;
		height: auto;
		position: relative;
	}
	.cg__headerContainer {
		position: relative;
		padding-left: 20px;
		padding-right: 20px;
		@include min_tablet {width: 750px;}
		@include min_desktop {width: 970px;}
		@include min_ldesktop {width: 1170px;}
		@include min_ldesktop2 {width: 1320px;}

		/** header top bar components **/
		.cg__topBar {
			height: 60px;
			align-items: center;
			display: flex;
			float: right;
			@include max_tablet {
				width: 100%;
			}
			.cg__topBar-component {
				margin-left: 10px;
				margin-right: 10px;
				margin-bottom: 0px;
				position: relative;
				float: right;
				@include mobile {margin-left: 0px;}
				&.cg__single-component {
					margin-left: 0px;
					margin-right: 0px;
					@include mobile {
						margin-left: 4px;
						margin-right: 4px;
					}
				}
				> li {
					margin-left: 10px;
					margin-right: 10px;
					@include mobile {margin-right: 0px;}
				}
				li a {
					color: #fff;
					font-size: 11px;
					line-height: 24px;
					opacity: .8;
					font-family: #{$titleFont};
				}
				li a:hover {opacity: .9;}
			}
			.cg__socialIcons li {
				margin: 2px;
				a {font-size: 22px;}
			}
			.cg_language-panel li a {
				font-size: 10px;
				color: #444;
			}
		}
		.cg__topMenu-wrapper {
			position: relative;
			@include max_tablet {
				margin-right: auto;
			}
			/* top menu */
			.cg__topMenu {
				li a {
					@include max_ldesktop {color: #6f6b6b;}
				}
				li {
					@include min_ldesktop { position: relative; }
					@include max_ldesktop {
						display: block;
						float: none;
						margin: 0;
						padding: 0;
					}
				}
				@include max_ldesktop {
					display: none;
					float: none;
					margin: 0;
					position: absolute;
					top: 25px;
					right: -10px;
				}
			}
			&.is-opened .cg__topMenu {
				@include max_ldesktop {display: block;}
			}
		}
		/** header menu bar **/
		.cg__menuBar {
			height: 100px;
			float: right;
			display: table;
			@include max_tablet {width: 100%;}
		}
	}
}

/* Subheader */
.cg__subHeader {
	height: 400px;
	background-color: #f4f4f4;
	position: relative;
	top: 0;
	z-index: 0;
	width: 100%;
	&--h840 {
		height: 840px;
		@include ldesktop {height: 660px;}
		@include max_desktop {height: 400px;}
	}
	.cg__subHeader-overlay {
		background-color: rgba(29,31,64,0.8);
		z-index: 2;
		&--style2 {
			background-color: rgba(29,27,27,0.5);
			z-index: 2;
		}
	}
	.cg__subHeader-img {
		position: absolute;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
	}
	.cg__subHeader-contentWrapp {
		padding-top: 240px;
		.container {
			padding: 0 30px;
			z-index: 2;
			position: relative;
		}
		.cg__breadcrumbs {
			margin: 0 0 10px;
			list-style: none;
			float: left;
			padding: 0;
			@include max_tablet {width: 100%;}
			li {
				color: hsla(0,0%,100%,.5);
				font-size: 11px;
				float: left;
				position: relative;
				text-transform: uppercase;
				a {
					color: hsla(0,0%,100%,.85);
				}
				&:not(:first-of-type) {
					&:before {
						content: "/";
						display: inline-block;
						margin: 0 7px;
					}
				}
			}
		}
		.cg__subheader-title {
			margin-top: -7px;
			h2 {
				font-size: 30px;
				line-height: 1.3;
				margin: 0 0 5px;
				@include max_tablet {
					font-size: 22px;
					text-align: left;
				}
			}
		}
	}
}
