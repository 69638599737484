.cg__counterElementWrapper {
	padding-top: 75px;
	margin-bottom: 30px;
	p {
		font-size: 56px;
		line-height: 68px;
		font-weight: 700;
		color: #ffffff;
		font-family: #{$titleFont};
		@include tablet_landscape {
			font-size: 30px;
			line-height: 50px;
		}
		@include ldesktop {
			font-size: 47px;
			line-height: 70px;
		}
	}
}
