.cg__latestPosts {
	position: relative;
	.cg__latestPosts-viewall {
		position: absolute;
		top: -10px;
		right: 0;
		font-size: 10px;
		font-weight: bold;
		color: #000;
		&:hover {
			color: #{$templateColor};
		}
	}
	.cg__latestPost-item {
		.cg__latestPosts-thumb {
			margin-right: 10px;
			margin-top: 2px;
			position: relative;
			display: inline-block;
			margin-bottom: 25px;
		}
		h4 {
			font-size: 13px;
			font-weight: 600;
			line-height: 20px;
			margin: 0 0 5px;
			padding: 0;
			vertical-align: middle;
			a {
				color: #000;
				&:hover {color: #{$templateColor};}
				&:focus {outline: none; }
			}
		}
		p {
			line-height: 1.7;
		}
		img {
			width: auto;
		}
	}
}
