.cg__customMenu {
	margin-bottom: 20px;
	&.cg__customMenu--style1 {
		ul {
			li {
				position: relative;
				a {
					font-size: 13px;
					line-height: 32px;
					color: rgba(255,255,255,0.5);
					display: block;
					padding: 10px 0;
					position: relative;
					border-bottom: 1px solid rgba(255,255,255,0.05);
					&:before {
						content: '';
						position: absolute;
						bottom: -1px;
						left: 0;
						width: 0;
						height: 1px;
						transition: all .15s ease-out;
						background-color: #{$templateColor};
					}
					&:after {
						content: "\e258";
						color: #fff;
						opacity: 0.05;
						position: absolute;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
						transition: all .15s ease-out;
						display: inline-block;
						font-family: 'Glyphicons Halflings';
						font-style: normal;
						font-weight: 400;
						line-height: 1;
					}
					&:hover {
						color: rgba(255,255,255,0.8);
						&:after {
							opacity: 0.2;
						}
						&:before {
							width: 100%;
						}
					}
			}
		}
	}
}
&.cg__customMenu--style2 {
	ul {
		li {
			position: relative;
			a {
				font-size: 11px;
				line-height: 36px;
				font-weight: 700;
				color: #444;
				display: block;
				padding: 10px 15px;
				margin-bottom: 3px;
				position: relative;
				background: rgba(0,0,0,0.1);
				border-radius: 2px;
				transition: color .15s cubic-bezier(0.77,0,0.175,1);
				overflow: hidden;
				font-family: #{$titleFont};
				&:hover {
					color: #fff;
					&:before {
						width: 100%;
					opacity: 1;
					transform: translateX(0) skewX(0);
					}
				}
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					border-radius: 2px;
					opacity: 0;
					transform: translateX(-103%) skewX(10deg);
					transition: all .15s cubic-bezier(0.77,0,0.175,1);
					background-color: #{$templateColor};
				}
				span {
					position: relative;
				}
				&.active {
					color: #fff;
					&:before {
						width: 100%;
						opacity: 1;
						transform: translateX(0) skewX(0);
						}
					}
				}
			}
		}
	}
}
