/* Contact form default style */
button, input, optgroup, select, textarea {
	font-family: sans-serif;
}
input, optgroup, select, textarea {
	margin-bottom: 15px;
	font-size: 13px;
	height: auto;
	outline: none;
	border: 1px solid #D9D9D9;
	line-height: 2;
	padding: 26px 10px 6px;
	display: block;
	color: #555;
	background-color: #fff;
}
input[type="text"], input[type="password"], input[type="email"], textarea, select {
	width: 100%;
}
label {
	color: #969696;
	cursor: text;
	font-size: 10px;
	text-transform: uppercase;
	position: absolute;
	top: 6px;
	left: 10px;
	font-family: #{$titleFont};
}
input:focus+label,
textarea:focus+label,
select:focus+label {color: silver;}
select {padding-left: 15px; padding-top:30px;}
.cg__form-component {position: relative;}
textarea {resize: vertical;}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
	select {
		appearance: none;
		box-shadow: inset 1px 2px 0 rgba(0,0,0,.06);
		background-image: url("../img-assets/select-arrow.svg");
		background-repeat: no-repeat;
		background-size: 6px 12px;
		background-position: calc(100% - 10px) 50%;
		background-position-y: center;
		background-position-x: calc(100% - 10px);
	}
}
input[type="password"]:focus,
input[type="email"]:focus,
input[type="text"]:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus,
textarea:focus {
	outline: none;
}
input[type="radio"], input[type="checkbox"] { display: inline-block; }
.cg__submitBtn {
	text-transform: initial;
	font-size: 14px;
		line-height: 1.3333333;
		margin: auto;
		display: block;
}
/* contact form validation */
.js-disable-action {pointer-events: none;}
.js-cf-message {
	display: none;
	border-left: solid 3px transparent;
	padding-left: 5px;
}
.js-response-success {border-left-color: #9bf442;}
.js-response-error {border-left-color: #dd0000;}

.cg__form--style1 {
	input {
		height: 50px;
		float: left;
		border: 0;
		vertical-align: middle;
		outline: none;
		margin: 0;
		top: 0;
		border-radius: 0;
		transition: padding-left .15s ease-out;
		padding-left: 20px;
		margin-bottom: 10px;
		&:focus {
			padding-left: 30px;
			border-color: rgba(0,0,0,.26);
			box-shadow: inherit;
		}
	}
}
