.is-animating {
	visibility:visible;
	animation-fill-mode: both;
	animation-timing-function: ease-out;
}

@keyframes fadeIn {
	from {opacity: 0;}
	to {opacity: 1;}
}

.fadeIn {animation-name: fadeIn;}

@keyframes bounceIn {
	from, 20%, 40%, 60%, 80%, to {
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0% {
		opacity: 0;
		transform: scale3d(.3, .3, .3);
	}

	20% {
		transform: scale3d(1.1, 1.1, 1.1);
	}

	40% {
		transform: scale3d(.9, .9, .9);
	}

	60% {
		opacity: 1;
		transform: scale3d(1.03, 1.03, 1.03);
	}

	80% {
		transform: scale3d(.97, .97, .97);
	}

	to {
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
}

.bounceIn {animation-name: bounceIn;}

@keyframes fadeInLeft {
	from {
		opacity: 0;
		transform: translate3d(-30%, 0, 0);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

.fadeInLeft {animation-name: fadeInLeft;}


@keyframes fadeInRight {
	from {
		opacity: 0;
		transform: translate3d(30%, 0, 0);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

.fadeInRight {animation-name: fadeInRight;}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 30%, 0);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

.fadeInUp { animation-name: fadeInUp;}