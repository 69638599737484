/* layout */
$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 992px;
$large-desktop-width: 1200px;
$slarge-desktop-width: 1680px;
$glyph: 'Glyphicons Halflings';
$desktop-height: 1050px;

/* colors */
$templateColor: #0d5195;
$titleColor: #0a0a0a;
$hoverColor: #0a4177;
$elementsColor: #181834;

/* fonts */
$defaultFont: 'Open Sans',Helvetica,Arial,sans-serif;
$titleFont: 'Montserrat',Helvetica,Arial,sans-serif;