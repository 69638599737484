.cg__testimonialWrapper {
	width: 100%;
	&.sh__scheme--dark {
		color: #dcdcdc;
		.cg__testimonial {
			color: #181818;
		}
	}
	&.cg__scheme--light {
		color: #535353;
		.cg__testimonial {

		}
	}
}
.cg__testimonial {
	float: left;
	margin-bottom: 30px;
	width: calc(100% - 50px);
	.cg__testimonial-text {
		background: #f4f4f4;
		padding: 30px 25px 30px;
		line-height: 2;
		border-radius: 3px;
		font-size: 16px;
		font-weight: 400;
		font-style: italic;
		position: relative;
	}
	.cg__testimonial-info {
		line-height: 15px;
		margin-top: 15px;
		.cg__testimonial-img {
			margin-top: -35px;
			position: relative;
			width: 110px;
			height: 110px;
			border-radius: 50%;
			overflow: hidden;
			float: left;
			margin-right: 20px;
			margin-left: 25px;
			border: 5px solid rgba(255,255,255,0.2);
			background-clip: content-box;
			object-fit: cover;
		}
		h4 {
			font-size: 16px;
			line-height: 1.4;
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 0;
			margin-top: 0;
		}
		.cg__testimonial-position {
			font-size: 13px;
			line-height: 1.4;
			font-weight: 300;
			opacity: 0.5;
			margin-bottom: 5px;
		}
		.glyphicon-star {
			opacity: .6;
				font-size: 9px;
		}
	}
}
.cg__Logos {
	position: relative;
	float: left;
	width: 100%;
	.cg__logo-item {
		float: left;
		line-height: 60px;
		vertical-align: middle;
		text-align: center;
		.cg__logo-link {
			&:focus {
				outline: none;
			}
			display: block;
			padding: 10px;
			img {
				max-height: 60px;
				margin: 0 auto;
				width: auto;
			}
		}
	}
}
