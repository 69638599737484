.cg__media {
	min-height: 440px;
	height: 440px;
	position: relative;
	display: flex;
	overflow: hidden;
	align-items: center;
	h4 {
		font-size: 16px;
		font-weight: 700;
		color: #0a0a0a;
		&.cg__elem--white {color: #fff;}
	}
	p {
		line-height: 34px;
		color: #0a0a0a;
		&.cg__elem--white {color: rgba(255,255,255,0.74);}
	}
	&--style2 {
		min-height: 360px;
		height: 360px;
		@include max_tablet {
			min-height: 360px;
			height: 360px;
		}
	}
	@include max_ldesktop {
		min-height: auto;
		height: auto;
	}
	@include max_tablet {
		min-height: 440px;
		height: 440px;
	}
	@include mobile {
		min-height: 300px;
		height: auto;
	}
	.cg__media-overlay {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		background: linear-gradient(to bottom, rgba(15,15,18,0) 0%,rgba(20,20,20,0.95) 100%);
		&--style2 {background: rgba(13,81,149,1);}
		&--style3 {background-color: rgba(29,31,64,0.5);}
		&--grid {
			background-image: linear-gradient(to right top,transparent 33%,black 33%,black 66%,transparent 66%);
			background-size: 3px 3px;
		}
	}
	.cg__media-details {
		width: calc(100% - 50px);
		margin: 10px 25px;
		position: absolute;
		bottom: 0;
		&--center {
			align-self: center;
			position: relative;
		}
	}
	.cg__iconBox {
		&.cg__iconBox--style1 {
			.cg__iconbox-title {
				font-size: 18px;
				line-height: 30px;
			}
		}
	}
}
