.cg__skillsDiagram {
	svg {
		overflow: visible;
		width:100%;
		height:100%;
	}

	.legend {
		float:left;
		width:250px;
		margin-top:140px;
	}
	#content h1 {
		font-family:'Cabin Sketch', arial, serif;
		text-shadow:3px 3px 0 #ddd;
		color:#193340;
		font-size:40px;
		margin-bottom:40px;
		text-align:right;
	}
	.skills {
		float:left;
		clear:both;
		width:100%;
	}
	.skills ul,
	.skills li {
		display:block;
		list-style:none;
		margin:0;
		padding:0;
	}
	.skills li {
		float:right;
		clear:both;
		padding:0 15px;
		height:35px;
		line-height:35px;
		color:#fff;
		margin-bottom:1px;
		font-size:18px;
	}
	.skills .skill1 {
		background:#97BE0D;
	}
	.skills .skill2 {
		background:#D84F5F;
	}
	.skills .skill3 {
		background:#88B8E6;
	}
	.skills .skill4 {
		background:#BEDBE9;
	}
	.skills .skill5 {
		background:#EDEBEE;
	}
	#diagram {
		float:right;
		width:auto;
		height:auto;
	}
	.cg__diagram {height: 500px;}
	.get {
		display:none;
	}
	a {
		text-decoration:none;
		color:#333;
	}
	&.cg__skillsDiagram--style1 {
		.legend {display: none;}
	}
}
